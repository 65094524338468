import { useApolloClient } from '@apollo/client';
import InputIcon from '@mui/icons-material/Input';
import { Button, CardActions, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import InputAdornment from '@mui/material/InputAdornment';
import { createStyles, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { addFactor, Factor, FactorType, Pose2Pose2Data, PriorPose2Data } from 'navabilitysdk';
import React from 'react';
import ReactJson from 'react-json-view';
import { DarkNegativeDeleteIcon, DarkNegativeSendIcon } from '../icons/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({  
    card: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.primary
    },
    textField: {
      width: "100%",
      margin: theme.spacing(1)
    }    
  }),
);

const FactorEditorCard = ({client}:any) => {
  const classes = useStyles();
  const globalState = useApolloClient();

  const vectorizeTags = (tags:String) => {
    return tags.replace(/\s/g,"").toUpperCase().split(',');
  }
  const vectorizeVariableOrderSymbols = (tags:String) => {
    return tags.replace(/\s/g,"").split(',');
  }
  const stringifyVector = (vals:String[]) => {
    return vals.join(",")
  }

  const defaultTags = ["FACTOR"]
  const defaultPriorPose2Variables = ["x0"]
  const defaultPriorPose2Factor = Factor([...defaultPriorPose2Variables,"f1"].join(""), FactorType.PRIORPOSE2, defaultPriorPose2Variables, PriorPose2Data(), defaultTags)
  const defaultPose2Pose2Variables = ["x0","x1"]
  const defaultPose2Pose2Factor = Factor([...defaultPose2Pose2Variables,"f1"].join(""), FactorType.POSE2POSE2, defaultPose2Pose2Variables, Pose2Pose2Data(), defaultTags)

  const [isPrior, setIsPrior] = React.useState(true);
  const [label, setLabel] = React.useState(defaultPriorPose2Factor.label);
  const [fncType, setFncType] = React.useState<String>(defaultPriorPose2Factor.fnctype);
  const [tags, setTags] = React.useState(stringifyVector(defaultTags));
  const [variableOrderSymbols, setVariableOrderSymbols] = React.useState(stringifyVector(defaultPriorPose2Variables));
  const [factorPayload, setFactorPayload] = React.useState<Factor>(defaultPriorPose2Factor);
  
  const handleLabelChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
    let factorData = isPrior ? PriorPose2Data() : Pose2Pose2Data()
    setFactorPayload(Factor(event.target.value, fncType, vectorizeVariableOrderSymbols(variableOrderSymbols), factorData, vectorizeTags(tags)))
  }
  
  const handleFncTypeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFncType(event.target.value);
    let factorData = isPrior ? PriorPose2Data() : Pose2Pose2Data()
    setFactorPayload(Factor(label, event.target.value, vectorizeVariableOrderSymbols(variableOrderSymbols), factorData, vectorizeTags(tags)))
  };
  const handleTagsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTags(event.target.value);
    let factorData = isPrior ? PriorPose2Data() : Pose2Pose2Data()
    setFactorPayload(Factor(label, fncType, vectorizeVariableOrderSymbols(variableOrderSymbols), factorData, vectorizeTags(event.target.value)))
  };
  const handleVariableOrderSymbolsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVariableOrderSymbols(event.target.value);
    let factorData = isPrior ? PriorPose2Data() : Pose2Pose2Data()
    setFactorPayload(Factor(label, fncType, vectorizeVariableOrderSymbols(event.target.value), factorData, vectorizeTags(tags)))
  };

  const handleSendFactor = () => {
    addFactor(globalState, client, factorPayload)
  }

  const handlePayloadEdit = (data:any) => {
    if(data.new_value == null) return false;
    if(data.new_value.trim() == "") return false;
    setFactorPayload(data.updated_src);
    return true;
  }

  const handleResetPayload = () => {
    if (isPrior) 
      handleResetPayloadToPrior();
    else
      handleResetPayloadToFactor();
  }

  const handleResetPayloadToPrior = () => {
    setLabel(defaultPriorPose2Factor.label);
    setFncType(defaultPriorPose2Factor.fnctype);
    setTags(stringifyVector(defaultTags));
    setVariableOrderSymbols(stringifyVector(defaultPriorPose2Variables))
    setFactorPayload(defaultPriorPose2Factor);
  }

  const handleResetPayloadToFactor = () => {
    setLabel(defaultPose2Pose2Factor.label);
    setFncType(defaultPose2Pose2Factor.fnctype);
    setTags(stringifyVector(defaultTags));
    setVariableOrderSymbols(stringifyVector(defaultPose2Pose2Variables))
    setFactorPayload(defaultPose2Pose2Factor);
  }

  const handleFactorTypeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrior(event.target.checked)
    if (event.target.checked) {
      handleResetPayloadToPrior()
    } else {
      handleResetPayloadToFactor()
    }
  }

  return (
    <Card className={classes.card}>
      <CardHeader title={"Factor Editor"}/>
      <CardContent>
        <FormGroup>
          <FormControlLabel control={<Switch checked={isPrior} color="primary" onChange={handleFactorTypeChanged} name="isprior"/>} label="Is Prior"/>
        </FormGroup>
        <TextField className={classes.textField} variant="outlined" 
            id="inputLabel" label="Factor Label" value={label} onChange={handleLabelChanged}
            InputProps={{startAdornment: (
              <InputAdornment position="start">
                <InputIcon/>
              </InputAdornment>),
            }}/>
        <TextField className={classes.textField} variant="outlined" 
            id="inputFactorType" label="Factor Type" value={fncType} onChange={handleFncTypeChanged}
            InputProps={{startAdornment: (
              <InputAdornment position="start">
                <InputIcon/>
              </InputAdornment>),
            }}/>
        <TextField className={classes.textField} variant="outlined" 
            id="inputTags" label="Tags" helperText="Comma-delimited list of tags (FACTOR must be one)" value={tags} onChange={handleTagsChanged}
            InputProps={{startAdornment: (
              <InputAdornment position="start">
                <InputIcon/>
              </InputAdornment>),
            }}/>
        <TextField className={classes.textField} variant="outlined" 
            id="inputVariableOrderSymbols" label="Variables" helperText="Comma-delimited list of related variables to this factor" value={variableOrderSymbols} onChange={handleVariableOrderSymbolsChanged}
            InputProps={{startAdornment: (
              <InputAdornment position="start">
                <InputIcon/>
              </InputAdornment>),
            }}/>
        <Card variant="outlined">
          <CardHeader title="Input"/>
          <CardContent>
            <Typography>Edit this directly if you want to customize you payload further.</Typography>
            <ReactJson theme="hopscotch" src={factorPayload} onEdit={handlePayloadEdit}/>
          </CardContent>
        </Card>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="secondary" onClick={handleSendFactor} 
          startIcon={<DarkNegativeSendIcon fontSize="large" />}>
          Send Factor
        </Button>
        <Button variant="contained" color="secondary" onClick={handleResetPayload} 
          startIcon={<DarkNegativeDeleteIcon fontSize="large" />}>
          Reset Payload
        </Button>
      </CardActions>
    </Card>
  );
}

export default FactorEditorCard;
