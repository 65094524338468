import { Box } from '@mui/material';
import React from "react";
import SubscriptionUpdates from "../../components/activity/subscription-updates";
import SEO from "../../components/common/seo";
import DeveloperSandbox from "../../components/developer-sandbox/developer-sandbox";
const ExperimentPage = () => {
  return (
    <Box style={{ height: '95vh', overflowY: 'auto' }}>
      <Box>
      <SEO title="NavAbility Experiment" />
      {/* <Paywall> */}
        <DeveloperSandbox />
        <SubscriptionUpdates />
      {/* </Paywall> */}
      </Box>
    </Box>
  )
}

export default ExperimentPage
