import { gql, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { Client } from 'navabilitysdk';
import React from 'react';
import { INITIAL_STATE } from '../../global/state/schema';
import UnderConstructionAlert from '../common/alerts/UnderConstructionAlert';
import PayloadSenderCard from './payload-sender-card';

const ACCOUNT_QUERY = gql`
  query GetAccount {
    account @client {
      userId
      email
      phone
      isAuthenticated
    }
  }
`;

const DEVICE_QUERY = gql`
  query GetDevice {
    device @client {
      robotId
      sessionId
    }
  }
`;

const FILTERS_QUERY = gql`
  query GetFilters {
    filters @client {
      twin {
        userId
        robotId
        sessionId
        variableLabel
        requestId
      }
      swarm {
        environmentIds
        userIds
        robotIds
        sessionIds
      }
    }
  }
`;

const DeveloperSandbox = () => {
  const accountQuery = useQuery(ACCOUNT_QUERY);
  const account = accountQuery?.data?.account || INITIAL_STATE.account;
  const deviceQuery = useQuery(DEVICE_QUERY);
  const device = deviceQuery?.data?.device || INITIAL_STATE.device;
  const filterQuery = useQuery(FILTERS_QUERY);
  const filters = filterQuery?.data?.filters || INITIAL_STATE.filters;

  const client = Client(account.userId, device.robotId, device.sessionId);

  return (
    <Box>
      <UnderConstructionAlert />
      <PayloadSenderCard client={client} filters={filters} />
    </Box>
  );
};
export default DeveloperSandbox;
