import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { createStyles, Theme, useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import React from 'react';
import DemoGraphsCard from './demo-graphs-card';
import FactorEditorCard from './factor-editor-card';
import SolveCard from './solve-card';
import VariableEditorCard from './variable-editor-card';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: "100%",
    }    
  }),
);

const PayloadSenderCard = ({client, filters}:any) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const twin = {
    userId: filters.twin.userId,
    robotId: filters.twin.robotId,
    sessionId: filters.twin.sessionId
  }

  const swarm = {
    environmentIds: filters.swarm.environmentIds || [],
    userIds: filters.swarm.userIds || [],
    robotIds: filters.swarm.robotIds || [],
    sessionIds: filters.swarm.sessionIds || []
  }


  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example">
          <Tab label="Demonstrations" {...a11yProps(0)}/>
          <Tab label="Variables" {...a11yProps(1)}/>
          <Tab label="Factors" {...a11yProps(2)}/>
          <Tab label="Solve" {...a11yProps(3)}/>
        </Tabs>
      </AppBar>
      {/* <SwipeableViews 
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}> */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          <DemoGraphsCard client={client}/>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <VariableEditorCard client={client}/>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <FactorEditorCard client={client}/>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <SolveCard client={client} twin={twin} swarm={swarm}/>
        </TabPanel>
      {/* </SwipeableViews> */}
    </div>
  );
}

export default PayloadSenderCard;
