import { useApolloClient } from '@apollo/client';
import { Button, CardActions, Theme, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { createStyles, makeStyles } from '@mui/styles';
import { Client, demoCanonicalHexagonal } from 'navabilitysdk';
import React from 'react';
import { DarkNegativeSendIcon, HexagonalIcon } from '../icons/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.primary
    },
    textField: {
      width: "100%",
      margin: theme.spacing(1)
    }
  }),
);

const DemoGraphsCard = ({client}:{client:Client}) => {
  const classes = useStyles();

  const globalState = useApolloClient()

  const handleSendHexagonal = () => {
    demoCanonicalHexagonal(globalState, client)
  }

  return (
    <Card className={classes.card}>
      <CardHeader 
        title="Demonstration Data"
        subheader="A procured selection of the finest vintage demonstration data, direct from our navigation sommeliers and replete with flavors of oak, tannins, and temperospatial mushrooms to delight your tastebuds."/>
      <CardContent>

        <Card variant="outlined" className={classes.card}>
          <CardHeader title="Hexagonal Graph"
            avatar={
                <HexagonalIcon fontSize="large" />
            }>
          </CardHeader>
          <CardContent>
            <Typography>
              A demonstration 2D graph of a robot following a hexagonal path.
              </Typography>
            </CardContent>
          <CardActions>
            <Button variant="contained" color="secondary"
              startIcon={<DarkNegativeSendIcon fontSize="large" />}
              onClick={handleSendHexagonal}>Send
            </Button>
          </CardActions>
        </Card>

        {/* <Card variant="outlined" className={classes.childCard}>
          <CardHeader title="Beehive"
            avatar={
                <BeehiveIcon fontSize="large" />
            }>
          </CardHeader>
          <CardContent>
            <Typography>
              An extension of the hexagonal example for building larger hexagonal graphs.
              </Typography>
            </CardContent>
          <CardActions>
            <Button 
              startIcon={<SendIcon/>} 
              color="primary"
              disabled={true}
              onClick={handleSendHexagonal}>Send</Button>
          </CardActions>
        </Card> */}

      </CardContent>
    </Card>
  );
}

export default DemoGraphsCard;
