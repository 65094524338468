import { useApolloClient } from '@apollo/client';
import { Button, CardActions, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { DarkNegativeSendIcon, SolveSwarmIcon, SolveThatIcon, SolveThisIcon } from '../icons/icons';

import { Client, Scope, solveFederated, solveSession } from 'navabilitysdk';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.primary
    },
    textField: {
      width: "100%",
      margin: theme.spacing(1)
    }
  }),
);

const SolveCard = ({client, twin, swarm}:{client:Client,twin:Client,swarm:Scope}) => {
  const classes = useStyles()
  const globalState = useApolloClient()

  const handleDeviceSolve = () => {
    solveSession(globalState, client)
  }

  const handleTwinSolve = () => {
    solveSession(globalState, twin)
  }

  const handleSwarmSolve = () => {
    solveFederated(globalState, swarm)
  }

  return (
    <Card className={classes.card}>
      <CardHeader 
        title="Navigation AI"
        subheader="After multisensory data is pumped into the twin graph, we need to run solvers to increase the sensory awareness of our robot."/>
      <CardContent>

        <Card variant="outlined" className={classes.card}>
          <CardHeader title="Solve This Device"
            avatar={
              <SolveThisIcon fontSize="large" />
            }>
          </CardHeader>
          <CardContent>
            <Typography>
              Run navigation AI on current device <br/>
              User: {client.userId} <br/>
              Robot: {client.robotId} <br/>
              Session: {client.sessionId}
            </Typography>
          </CardContent>
          <CardActions>
          <Button variant="contained" color="secondary"
            startIcon={<DarkNegativeSendIcon fontSize="large" />} 
            onClick={handleDeviceSolve}>
            Send
          </Button>
          </CardActions>
        </Card>

        <Card variant="outlined" className={classes.card}>
          <CardHeader title="Solve Selected Twin"
            avatar={
              <SolveThatIcon fontSize="large" />
            }>
          </CardHeader>
          <CardContent>
            <Typography>
              Run navigation AI on selected twin <br/>
              User: {twin.userId} <br/>
              Robot: {twin.robotId} <br/>
              Session: {twin.sessionId}
            </Typography>
          </CardContent>
          <CardActions>
          <Button variant="contained" color="secondary"
            startIcon={<DarkNegativeSendIcon fontSize="large" />} 
            onClick={handleTwinSolve}>
            Send
          </Button>
          </CardActions>
        </Card>

        <Card variant="outlined" className={classes.card}>
          <CardHeader title="Solve Selected Swarm"
            avatar={
              <SolveSwarmIcon fontSize="large" />
            }>
          </CardHeader>
          <CardContent>
            <Typography>
              Run navigation AI on swarm selections <br/>
              Environments: {swarm.environmentIds.join(", ")} <br/>
              Users: {swarm.userIds.join(", ")} <br/>
              Robots: {swarm.robotIds.join(", ")} <br/>
              Sessions: {swarm.sessionIds.join(", ")}
            </Typography>
          </CardContent>
          <CardActions>
          <Button variant="contained" color="secondary"
            startIcon={<DarkNegativeSendIcon fontSize="large"/>} 
            onClick={handleSwarmSolve}>
            Send
          </Button>
          </CardActions>
        </Card>

      </CardContent>
    </Card>
  );
}

export default SolveCard;
