import { useApolloClient } from '@apollo/client';
import InputIcon from '@mui/icons-material/Input';
import { Button, CardActions, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import InputAdornment from '@mui/material/InputAdornment';
import { createStyles, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { addVariable, Client, Variable, VariableType } from 'navabilitysdk';
import React from 'react';
import ReactJson from 'react-json-view';
import { DarkNegativeDeleteIcon, DarkNegativeSendIcon } from '../icons/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.primary
    },
    textField: {
      width: "100%",
      margin: theme.spacing(1)
    }    
  }),
);

const VariableEditorCard = ({client}:{client:Client}) => {
  const classes = useStyles()
  const globalState = useApolloClient()

  const vectorizeTags = (tags:String) => {
    return tags.replace(/\s/g,"").toUpperCase().split(',');
  }
  const stringifyVector = (vals:String[]) => {
    return vals.join(",")
  }

  const defaultTags = ["VARIABLE"]
  const defaultVariable = Variable("x0", VariableType.POSE2, defaultTags)

  const [label, setLabel] = React.useState(defaultVariable.label)
  const [variableType, setVariableType] = React.useState(defaultVariable.variableType)
  const [tags, setTags] = React.useState(stringifyVector(defaultTags))
  const [variablePayload, setVariablePayload] = React.useState(defaultVariable)

  const handleLabelChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
    setVariablePayload(Variable(event.target.value, variableType, vectorizeTags(tags)));
  };
  const handleVariableTypeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVariableType(event.target.value);
    setVariablePayload(Variable(label, event.target.value, vectorizeTags(tags)));
  };
  const handleTagsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTags(event.target.value);
    setVariablePayload(Variable(label, event.target.value, vectorizeTags(event.target.value)));
  };

  const handleSendVariable = () => {
    addVariable(globalState, client, variablePayload)
  }

  const handlePayloadEdit = (data:any) => {
    if(data.new_value == null) return false;
    if(data.new_value.trim() == "") return false;
    setVariablePayload(data.updated_src);
    return true;
  }

  const handleResetPayload = () => {
    setLabel(defaultVariable.label);
    setVariableType(defaultVariable.variableType);
    setTags(stringifyVector(defaultTags));
    setVariablePayload(defaultVariable);
  }

  return (
    <Card className={classes.card}>
      <CardHeader title={"Variable Editor"}/>
      <CardContent>
        <TextField className={classes.textField} variant="outlined" 
            id="inputLabel" label="Variable Label" value={label} onChange={handleLabelChanged}
            InputProps={{startAdornment: (
              <InputAdornment position="start">
                <InputIcon/>
              </InputAdornment>),
            }}/>
        <TextField className={classes.textField} variant="outlined" 
            id="inputVariableType" label="Variable Type" value={variableType} onChange={handleVariableTypeChanged}
            InputProps={{startAdornment: (
              <InputAdornment position="start">
                <InputIcon/>
              </InputAdornment>),
            }}/>
        <TextField className={classes.textField} variant="outlined" 
            id="inputTags" label="Tags" helperText="Comma-delimited list of tags (VARIABLE must be one)" value={tags} onChange={handleTagsChanged}
            InputProps={{startAdornment: (
              <InputAdornment position="start">
                <InputIcon/>
              </InputAdornment>),
            }}/>
        <Card variant="outlined">
          <CardHeader title="Input"/>
          <CardContent>
            <Typography>Edit this directly if you want to customize you payload further.</Typography>
            <ReactJson theme="hopscotch" src={variablePayload} onEdit={handlePayloadEdit}/>
          </CardContent>
        </Card>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="secondary" onClick={handleSendVariable}
          startIcon={<DarkNegativeSendIcon fontSize="large" />}>
          Send
        </Button>
        <Button variant="contained" color="secondary" onClick={handleResetPayload}
          startIcon={<DarkNegativeDeleteIcon fontSize="large" />}>
          Reset
        </Button>
      </CardActions>
    </Card>
  );
}

export default VariableEditorCard;
